/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState, useRef } from "react";
import { Dialog, Button, makeStyles, Grid } from "@material-ui/core";
import CloseIcon from "../icons/close_icon";
import CustomTypography from "../utils/text";
import LoadingOverlayComponent from "./lodding_component";
import DropDownComponent from "./custom_dropdown";
import MultiSelect from "./custom_dropdown_multiselect";
import DatePickerComponent from "./date_picker";
import WarningMessage from "./warning_snackbar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  getBreakOptions,
  localStorageKey,
  apiEndPoints,
  reportParamters
} from "../apilayer/staticData";
import IconTextField from "./textbox_with_icons";

const useStyles = makeStyles({
  buttonClass: {
    "&:hover": {
      color: "white",
      background: "#003F2D",
    },
    background: "#003F2D",
    color: "white",
    height: 32,
    width: 180,
  },
  backButtonClass: {
    "&:hover": {
      background: "#003F2D",
      "& p": {
        color: "white!important",
      },
    },
    background: "#fff",
    border: "1px solid #003F2D",
    height: 32,
    width: 180,
  },
  disablebuttonClass: {
    "&:hover": {
      color: "white",
      background: "#d4d7d7",
    },
    background: "#d4d7d7",
    height: 32,
    width: 180,
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    overflow: "hidden",
    maxWidth: 624,
  },
});
const min = 0;
const max = 9999999;

/**
 * @typedef {Object} RenderReportModalProps
 * @property {string} [reportTitle]
 * @property {reportParamters} [reportParams]
 * @property {boolean} [open]
 * @property {Function} handleClose
 * @property {Function} onReportTypeSelectionChange
 */

/**
 * @param {RenderReportModalProps} props
 */

const RenderReportModal = ({
  reportTitle="Enter search criteria",
  reportParams=null,
  open=false, 
  handleClose, 
  onReportTypeSelectionChange
}) => {
  const myRef_divReportModal = useRef(null);
  const myRef_dvInputSection = useRef(null);
  const classes = useStyles();

  const [warningSnackbar, setWarningSnackbar] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [isLodding, setIsLodding] = useState(false);

  const [marketGroupOptions, setMarketGroupOptions] = useState([]);
  const [marketOptions, setMarketOptions] = useState([]);
  const [subMarketOptions, setSubMarketOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [neightbourhoodOptions, setNeightbourhoodOptions] = useState([]);

  const [selectedMarkets, setMarketGroupValue] = useState([]);
  const [selectedSubMarkets, setSubmarkete] = useState([]);
  const [selectedDistricts, setDistrict] = useState([]);
  const [selectedNeighborhoods, setNeightbourhood] = useState([]);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [breakValue, setBreakValue] = useState("");
  const [minPropValue, setMinPropValue] = useState(min);
  const [maxPropValue, setMaxPropValue] = useState(max);

  const [enableNext, setEnableNext] = useState(false);
  const [errorMarketGroupValue, setErrorMarketGroupValue] = useState(false);
  const [errorMarketGroupValues, setErrorMarketGroupValues] = useState(false);
  const [errorSubmarkets, setEerrorSubmarkets] = useState(false);
  const [errorDistricts, setErrorDistricts] = useState(false);
  const [errorNeighbourhoods, setErrorNeighbourhoods] = useState(false);
  const [errorBreak, setErrorBreak] = useState(false);
  const [errorDatePeriod, setErrorDatePeriod] = useState("");
  const [errorPropSize, setErrorPropSize] = useState(false);

  useEffect(() => {
    fetchDropdowndata();
    //set selected params
    setBreakValue(reportParams.break);
    if (reportParams.paramEntered) {       
      setMarketGroupValue(reportParams.marketGroup);
      if (reportParams.reportType === 2 || reportParams.reportType === 3) {
        setSubmarkete(reportParams.subMarket);
        setDistrict(reportParams.district);
        setNeightbourhood(reportParams.neightbourhood);
      }
      setDateFrom(new Date(reportParams.dateFrom));
      setDateTo(new Date(reportParams.dateTo));
      setMinPropValue(reportParams.minPropSize);
      setMaxPropValue(reportParams.maxPropSize); 
    }
  }, []);

  useEffect(() => {
    //do operation on state change
    fnEnableNext();
  }, [
    selectedMarkets,
    selectedSubMarkets,
    selectedDistricts,
    selectedNeighborhoods,
    dateFrom,
    dateTo,
    breakValue,
    minPropValue,
    maxPropValue,
  ]);

  useEffect(() => {
    setModalHeighOnValidation();
  }, [errorBreak, errorDatePeriod, errorPropSize]);

  
const setLocationOptions = (marketValues, submarketvalues, districtValues, neighborhoodValues) => {
  if (reportParams.reportType === 3) {
    let filtered_Markets = JSON.parse(sessionStorage.getItem(localStorageKey.LocationDropDownsData)).markets;
    
    // Filter available markets based on the selectionslu
    filtered_Markets = filtered_Markets.filter(market => 
      market.submarkets.some(submarkets => (submarketvalues.length > 0 ? submarketvalues.indexOf(submarkets.name) > -1 : true) &&
      submarkets.districts.some(districts => (districtValues.length > 0 ? districtValues.indexOf(districts.name) > -1 : true) &&
      districts.neighborhoods.some(neighborhoods => neighborhoodValues.length > 0 ? neighborhoodValues.indexOf(neighborhoods) > -1 : true)))
    );
    setMarketOptions(filtered_Markets);

    // Filter available sub markets based on the selections
    const filtered_Submarkets = filtered_Markets.filter(markets => marketValues.length > 0 ? marketValues.indexOf(markets.name) > -1 : true)
    .flatMap(markets => markets.submarkets).filter(submarkets => 
      submarkets.districts.some(districts => (districtValues.length > 0 ? districtValues.indexOf(districts.name) > -1 : true) &&
      districts.neighborhoods.some(neighborhoods => neighborhoodValues.length > 0 ? neighborhoodValues.indexOf(neighborhoods) > -1 : true))
    )
    setSubMarketOptions(filtered_Submarkets);

    // Filter available district based on the selections
    const filtered_District = filtered_Submarkets.filter(submarkets => submarketvalues.length > 0 ? submarketvalues.indexOf(submarkets.name) > -1 : true)
    .flatMap(submarkets => submarkets.districts).filter(districts =>
      districts.neighborhoods.some(neighborhoods => neighborhoodValues.length > 0 ? neighborhoodValues.indexOf(neighborhoods) > -1 : true)
    )
    setDistrictOptions(filtered_District);

    // Filter available neighborhood based on the selections
    const filtered_Neighborhood = filtered_District.filter(districts => districtValues.length > 0 ? districtValues.indexOf(districts.name) > -1 : true)
    .flatMap(districts => districts.neighborhoods);  
    setNeightbourhoodOptions(filtered_Neighborhood);   
  }
}
  const fetchDropdowndata = async () => {
    try {
      setIsLodding(true);    
      if (reportParams.reportType === 1) {
        var marketGroupsData = JSON.parse(
          sessionStorage.getItem(localStorageKey.MarketGroupsData)
        );
        if (!marketGroupsData) {
          const data = await axiosPrivate.get(apiEndPoints.MarketGroups);
          if (data.data.result) {
            marketGroupsData = data.data.result.market_groups;
            sessionStorage.setItem(
              localStorageKey.MarketGroupsData,
              JSON.stringify(marketGroupsData)
            );
          }
        }
        setMarketGroupOptions(marketGroupsData);
      } else {
        let locationDropdownsData = JSON.parse(
          sessionStorage.getItem(localStorageKey.LocationDropDownsData)
        );
        if (!locationDropdownsData) {     
          var data = await axiosPrivate.get(apiEndPoints.LocationDropDownsHierarchical);
          if (data.data.result) {
            locationDropdownsData = data.data.result;
            sessionStorage.setItem(
              localStorageKey.LocationDropDownsData,
              JSON.stringify(locationDropdownsData)
            );
          }
        }      
        const markets = locationDropdownsData.markets;
        //set the options by
        if(reportParams.paramEntered && reportParams.reportType === 3){
          setLocationOptions(reportParams.marketGroup, reportParams.subMarket, reportParams.district, reportParams.neightbourhood)
        } else{       
          //extract separate set of market, submarket, district and neighbour from heirarchical data       
        const submarkets = markets.flatMap(market => market.submarkets);     
        const districts = submarkets.flatMap(submarkets => submarkets.districts);
        const neighborhoods = districts.flatMap(districts => districts.neighborhoods);
        setMarketOptions(markets);
        setSubMarketOptions(submarkets);
        setDistrictOptions(districts);
        setNeightbourhoodOptions(neighborhoods);
        }              
      }
    } catch (error) {
      console.error("filed to load the locations...", error);
    } finally {
      setIsLodding(false);
    }
  };

  const fnEnableNext = () => {
    var isEnabled = true;
    if (reportParams.reportType === 1) {
      if (selectedMarkets == null || selectedMarkets.length < 1) {
        isEnabled = false;
      }
    } else {
      if (
        selectedMarkets.length < 1 &&
        selectedSubMarkets.length < 1 &&
        selectedDistricts.length < 1 &&
        selectedNeighborhoods.length < 1
      ) {
        isEnabled = false;
      }
    }
    if (breakValue == null || breakValue === "") {
      isEnabled = false;
    }
    if (!dateFrom || !dateTo) {
      isEnabled = false;
    }

    setEnableNext(isEnabled);
  };

  const validateForm = () => {
    var isValidated = true;
    if (breakValue == null || breakValue === "") {
      setErrorBreak(true);
      isValidated = false;
    }
    
    if(dateFrom.toString() === "Invalid Date"){
      setErrorDatePeriod(
        "Please select a valid date from 'Date from'"
      );
      isValidated = false;
    }
    if(dateTo.toString() === "Invalid Date"){
      setErrorDatePeriod(
        "Please select a valid date from 'Date to'"
      );
      isValidated = false;
    }

    if(dateFrom.toString() === "Invalid Date" && dateTo.toString() === "Invalid Date")
    {
      setErrorDatePeriod(
        "Please select a valid date from 'Date from' and 'Date to'"
      );
      isValidated = false;
    }
    if (!dateFrom || !dateTo) {
      if (!dateFrom && !dateTo)
        setErrorDatePeriod("Please select 'Date from' and 'Date to'");
      else if (!dateFrom) setErrorDatePeriod("Please select 'Date from'");
      else if (!dateTo) setErrorDatePeriod("Please select 'Date to'");
      isValidated = false;
    } else if (dateFrom.getTime() > dateTo.getTime()) {
      setErrorDatePeriod(
        "'Date from' should be less than or equal to 'Date to'"
      );
      isValidated = false;
    }
    if (minPropValue === "" || maxPropValue === "") {
      if (minPropValue === "" && maxPropValue === "")
        setErrorPropSize(
          "Please enter the 'Minimum' and 'Maximum' property size."
        );
      else if (minPropValue === "")
        setErrorPropSize("Please enter the 'Minimum' property size.");
      else if (maxPropValue === "")
        setErrorPropSize("Please enter the 'Maximum' property size.");
      isValidated = false;
    } else {
      if (minPropValue > maxPropValue) {
        setErrorPropSize(
          "'Minimum size' should be less than or equal to 'Maximum size'."
        );
        isValidated = false;
      }
    }
    setModalHeighOnValidation();
    return isValidated;
  };

  const handleOnNext = () => {
    if (validateForm()) {
      const objReportParams = {
        paramEntered: true,
        reportType: reportParams.reportType,
        marketGroup: selectedMarkets,
        subMarket: selectedSubMarkets,
        district: selectedDistricts,
        neightbourhood: selectedNeighborhoods,
        dateFrom:
          dateFrom.getFullYear() +
          "-" +
          ("0" + (dateFrom.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dateFrom.getDate()).slice(-2),
        dateTo:
          dateTo.getFullYear() +
          "-" +
          ("0" + (dateTo.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + dateTo.getDate()).slice(-2),
        break: breakValue,
        minPropSize: minPropValue,
        maxPropSize: maxPropValue,
      };
      onReportTypeSelectionChange(4, objReportParams);
    }
  };

  const handleOnBack = () => {
    onReportTypeSelectionChange(0);
  };

  const handlechangeMarketGroup = (value) => {
    setMarketGroupValue(value);
    if (
      (reportParams.reportType === 1 && value) ||
      (reportParams.reportType === 2 && value.length > 0)
    ) {
      setErrorMarketGroupValue(false);
    }
    setLocationOptions(value, selectedSubMarkets, selectedDistricts, selectedNeighborhoods);    
  };

  const handlechangeSubmarket = (value) => {
    setSubmarkete(value);    
    setLocationOptions(selectedMarkets, value, selectedDistricts, selectedNeighborhoods)
  };

  const handlechangeDistrict = (value) => {
    setDistrict(value);
    setLocationOptions(selectedMarkets, selectedSubMarkets, value, selectedNeighborhoods)
  };

  const handlechangeNeightbourhood = (value) => {
    setNeightbourhood(value);
    setLocationOptions(selectedMarkets, selectedSubMarkets, selectedDistricts, value)
  };

  const handlechangeDateFrom = (value) => {
    if (value != null) {
      setDateFrom(value.$d);
      setErrorDatePeriod("");
    } else {
      setDateFrom(null);
    }
  };

  const handlechangeDateTo = (value) => {
    if (value != null) {
      setDateTo(value.$d);
      setErrorDatePeriod("");
    } else {
      setDateTo(null);
    }
  };

  const handlechangeBreak = (value) => {
    setBreakValue(value);
    if (value) {
      setErrorBreak(false);
    }
  };

  const handlechangePropValue_Min = (value) => {
    setMinPropValue(value);
    setErrorPropSize("");
  };

  const handlechangePropValue_Max = (value) => {
    setMaxPropValue(value);
    setErrorPropSize("");
  };

  function calculateModalHeigh(reportType) {
    return reportType === 1 ? 430 : 620;
  }

  function setModalHeighOnValidation() {
    console.log(errorDatePeriod);
    if (myRef_divReportModal.current != null)
      myRef_divReportModal.current.style.height =
        calculateModalHeigh(reportParams.reportType) +
        (myRef_dvInputSection.current.clientHeight -
          (reportParams.reportType === 1 ? 277 : 449)) +
        "px";
  }

  return (
    <Dialog
      open={open}
      //onClose={handleClose}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 624,
          maxHeight: 500,
        },
      }}
    >
      <div
        id="divReportModal"
        ref={myRef_divReportModal}
        style={{
          flexDirection: "column",
          display: "flex",
          width: 624,
          height: calculateModalHeigh(reportParams.reportType),
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 30,
            paddingBottom: 14,
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={10} md={10} style={{ paddingLeft: 30 }}>
              <CustomTypography
                text={reportTitle}
                fontfamily="Calibre-SB"
                fontsize="24px"
                fontweight="500"
                lineheight="32px"
                fontcolor="#1F1E1E"
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid
            ref={myRef_dvInputSection}
            id="dvInputSection"
            container
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{
                paddingLeft: 20,
                paddingRight: 30,
                paddingBottom: 10,
              }}
            >
              <CustomTypography
                text={
                  "Search location*" // + (reportParams.reportType == 2 ? "*" : "")
                }
                fontsize="12px"
                fontweight="600"
                lineheight="24px"
                marginLeft={10}
                marginTop={8}
                fontcolor="#000"
              />
            </Grid>
            {/* Location Field: Defined Report */}
            {reportParams.reportType === 1 && (
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 5 }}
              >
                <MultiSelect
                  isMultiple={false}
                  title={"Market group" + "\xa0"}
                  preselectedValue={
                    reportParams.marketGroup.length > 0
                      ? reportParams.marketGroup
                      : null
                  }
                  options={marketGroupOptions}
                  handleOnChange={(val) => handlechangeMarketGroup(val)}
                  searchPlaceHolder={"Click to select market group"}
                />
                {errorMarketGroupValue && (
                  <CustomTypography
                    text={"Please select Market Group"}
                    className="errorTypographyCss"
                    marginLeft={0}
                    marginTop={5}
                  />
                )}
              </Grid>
            )}
            {/* Location Field: Flexible Report */}
            {(reportParams.reportType === 2 || reportParams.reportType === 3) && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginBottom: 15,
                  }}
                >
                  <MultiSelect
                    isMultiple={true}
                    title={"Market" + "\xa0"}
                    preselectedValue={
                      reportParams.marketGroup.length > 0
                        ? reportParams.marketGroup
                        : []
                    }
                    options={[...new Set(marketOptions.map(({ name }) => name).sort())]}
                    handleOnChange={(val) => handlechangeMarketGroup(val)}
                  />
                  {errorMarketGroupValues && (
                    <CustomTypography
                      text={"Please select Market Group"}
                      className="errorTypographyCss"
                      marginLeft={10}
                      marginTop={"-6px"}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginBottom: 15,
                  }}
                >
                  <MultiSelect
                    isMultiple={true}
                    title={"Submarket" + "\xa0"}
                    preselectedValue={
                      reportParams.subMarket.length > 0
                        ? reportParams.subMarket
                        : []
                    }
                    options={[...new Set(subMarketOptions.map(({ name }) => name).sort())]}
                    handleOnChange={(val) => handlechangeSubmarket(val)}
                  />
                  {errorSubmarkets && (
                    <CustomTypography
                      text={"Please select Submarket"}
                      className="errorTypographyCss"
                      marginLeft={10}
                      marginTop={"-6px"}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginBottom: 15,
                  }}
                >
                  <MultiSelect
                    isMultiple={true}
                    title={"District" + "\xa0"}
                    preselectedValue={
                      reportParams.district.length > 0
                        ? reportParams.district
                        : []
                    }
                    options={[...new Set(districtOptions.map(({ name }) => name).sort())]}
                    handleOnChange={(val) => handlechangeDistrict(val)}
                  />
                  {errorDistricts && (
                    <CustomTypography
                      text={"Please select District"}
                      className="errorTypographyCss"
                      marginLeft={10}
                      marginTop={"-6px"}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    marginBottom: 1,
                  }}
                >
                  <MultiSelect
                    isMultiple={true}
                    title={"Neighbourhood" + "\xa0"}
                    preselectedValue={
                      reportParams.neightbourhood.length > 0
                        ? reportParams.neightbourhood
                        : []
                    }
                    options={[...new Set(neightbourhoodOptions.sort())]}
                    handleOnChange={(val) => handlechangeNeightbourhood(val)}
                  />
                  {errorNeighbourhoods && (
                    <CustomTypography
                      text={"Please select Neighbourhood"}
                      className="errorTypographyCss"
                      marginLeft={10}
                      marginTop={"-6px"}
                    />
                  )}
                </Grid>
              </>
            )}
            {/* Common fileds for Defined and Flexible report */}
            <Grid
              container
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: 20,
                  paddingRight: 30,
                  paddingBottom: 10,
                }}
              >
                <CustomTypography
                  text={"Select period*"}
                  fontsize="12px"
                  fontweight="600"
                  lineheight="24px"
                  marginLeft={10}
                  marginTop={8}
                  fontcolor="#000"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid
                item
                xs={4}
                md={4}
                style={{
                  paddingLeft: 30,
                  paddingRight: 10,
                  marginBottom: 0,
                }}
              >
                <DatePickerComponent
                  height={45}
                  width={180}
                  preselectedValue={
                    reportParams.dateFrom !== ""
                      ? new Date(reportParams.dateFrom)
                      : null
                  }
                  callback={handlechangeDateFrom}
                  label={"Date from"}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                style={{
                  paddingLeft: 20,
                  paddingRight: 10,
                  marginBottom: 0,
                }}
              >
                <DatePickerComponent
                  height={45}
                  width={180}
                  preselectedValue={
                    reportParams.dateTo !== ""
                      ? new Date(reportParams.dateTo)
                      : null
                  }
                  callback={handlechangeDateTo}
                  label={"Date to"}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                style={{
                  paddingLeft: 5,
                  paddingRight: 30,
                  marginBottom: 0,
                }}
              >
                <DropDownComponent
                  //key={industryCodeValue}
                  title={"Break" + "\xa0"}
                  value={reportParams.break !== "" ? reportParams.break : null}
                  //error={industryCodeMandotary}
                  data={getBreakOptions()}
                  handleCallback={(value) => handlechangeBreak(value)}
                  firstValueText={"Choose duration type"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid
                item
                xs={8}
                md={8}
                style={{
                  paddingLeft: 30,
                  paddingRight: 10,
                  marginBottom: 0,
                }}
              >
                {errorDatePeriod && (
                  <CustomTypography
                    className="errorTypographyCss"
                    text={errorDatePeriod}
                    marginLeft={
                      errorDatePeriod.indexOf("Date from") < 0 ? 200 : 0
                    }
                    marginTop={0}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                style={{
                  paddingLeft: 5,
                  paddingRight: 30,
                  marginBottom: 0,
                }}
              >
                {errorBreak && (
                  <CustomTypography
                    text={"Please select duration"}
                    className="errorTypographyCss"
                    marginLeft={10}
                    marginTop={0}
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: 20,
                  paddingRight: 30,
                  paddingBottom: 5,
                }}
              >
                <CustomTypography
                  text={"Select property size"}
                  fontsize="12px"
                  fontweight="600"
                  lineheight="24px"
                  marginLeft={10}
                  marginTop={8}
                  fontcolor="#000"
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                md={6}
                style={{
                  paddingLeft: 30,
                  paddingRight: 5,
                  marginBottom: 0,
                }}
              >
                <IconTextField
                  id="txtMinPropSize"
                  label="Minimum size"
                  type="number"
                  min={min}
                  max={max}
                  textfieldValue={minPropValue}
                  callbackFunction={handlechangePropValue_Min}
                  startEl={" "}
                  EndEl={"sq.ft."}
                />
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                style={{
                  paddingLeft: 5,
                  paddingRight: 30,
                  marginBottom: 0,
                }}
              >
                <IconTextField
                  id="txtMaxPropSize"
                  label="Maximum size"
                  type="number"
                  min={min}
                  max={max}
                  textfieldValue={maxPropValue}
                  callbackFunction={handlechangePropValue_Max}
                  startEl={" "}
                  EndEl={"sq.ft."}
                />
              </Grid>
            </Grid>
            {/* Error Message */}
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: 30,
                  paddingRight: 20,
                  marginBottom: 20,
                }}
              >
                {errorPropSize && (
                  <CustomTypography
                    text={errorPropSize}
                    fontsize="16px"
                    fontweight="500"
                    lineheight="24px"
                    marginLeft={0}
                    marginTop={10}
                    fontcolor="#FF543E"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: 9, // : 20,
            width: "100%",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingLeft: "36%", marginBottom: 20 }}
            >
              <Button
                style={{ marginRight: "10px" }}
                className={classes.backButtonClass}
                disabled={false}
                onClick={() => handleOnBack()}
              >
                <CustomTypography
                  text={"Back"}
                  fontsize="16px"
                  fontfamily="Calibre-SB"
                  fontweight="500"
                  lineheight="16px"
                  color="rgb(31, 30, 30)"
                />
              </Button>
              <Button
                style={{ zIndex: 1 }}
                className={
                  !enableNext ? classes.disablebuttonClass : classes.buttonClass
                }
                disabled={!enableNext}
                onClick={() => handleOnNext()}
              >
                <CustomTypography
                  text={"Continue"}
                  fontsize="16px"
                  fontfamily="Calibre-SB"
                  fontweight="500"
                  lineheight="16px"
                  fontcolor="white"
                />
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {warningSnackbar && (
        <WarningMessage
          open={warningSnackbar}
          onCloseHandle={() => setWarningSnackbar(false)}
          severity={"warningSnackbar.severity"}
          name={"warningSnackbar.name"}
          message={"warningSnackbar.message"}
        />
      )}
    </Dialog>
  );
};
export default RenderReportModal;
