/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import { Dialog, Button, makeStyles, Grid } from "@material-ui/core";
import CloseIcon from "../icons/close_icon";
import CustomTypography from "../utils/text";
import WarningMessage from "./warning_snackbar";
import IconTextField from "./textbox_with_icons";
import {reportParamters} from "../apilayer/staticData";

const useStyles = makeStyles({
  buttonClass: {
    "&:hover": {
      color: "white",
      background: "#003F2D",
    },
    background: "#003F2D",
    color: "white",
    height: 32,
    width: 180,
  },
  backButtonClass: {
    "&:hover": {
      background: "#003F2D",
      "& p": {
        color: "white!important",
      },
    },
    background: "#fff",
    border: "1px solid #003F2D",
    color: "#003F2D",
    height: 32,
    width: 180,
  },
  disablebuttonClass: {
    "&:hover": {
      color: "white",
      background: "#d4d7d7",
    },
    background: "#d4d7d7",
    height: 32,
    width: 180,
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    overflow: "hidden",
    maxWidth: 624,
  },
});

/**
 * @typedef {Object} ReportNameModalProps
 * @property {string} [reportTitle]
 * @property {number} [reportType]
 * @property {reportParamters} [reportParams]
 * @property {boolean} [open]
 * @property {Function} handleClose
 * @property {Function} onReportTypeSelectionChange
 */

/**
 * @param {ReportNameModalProps} props
 */

const ReportNameModal = ({
  reportTitle = "Add report name",
  reportType = 0,
  reportParams = null,
  open = false,
  handleClose,
  onReportTypeSelectionChange,
}) => {
  const classes = useStyles();

  const [warningSnackbar, setWarningSnackbar] = useState(false);
  const [reportName, setRreportName] = useState("");
  const [reportNameError, setReportNameError] = useState();
  const [enableNext, setEnableNext] = useState(false);

  useEffect(() => {
    const d = new Date();
    let dformat = `${("0" + d.getDate()).slice(-2)}-${(
      "0" +
      (d.getMonth() + 1)
    ).slice(-2)}-${d.getFullYear()}-${
      ("0" + d.getHours()).slice(-2) +
      "-" +
      ("0" + d.getMinutes()).slice(-2) +
      "-" +
      ("0" + d.getSeconds()).slice(-2).toString()
    }`;
    setRreportName("StatsReport-" + dformat);
  }, []);

  useEffect(() => {    
    console.log(reportParams)
    fnEnableNext();
  }, [reportName]);

  const handleOnBack = () => {
    console.log(reportParams)
    onReportTypeSelectionChange(reportType, reportParams);
  };

  const fnEnableNext = () => {
    var isEnabled = true;

    if (reportName === null || reportName === "") {
      isEnabled = false;
    }

    setEnableNext(isEnabled);
  };
  const validateForm = () => {
    var isValidated = true;
    var rg1 = /^(?=[ \S])[^\\\/:*.?"<>|!@%^&=+~`#_$,;'{})()]+$/;
    if (reportName === null || reportName === "" || !rg1.test(reportName)) {
      setReportNameError(
        "Invalid file name. Please use only alphabets(A-Z, a-z), numbers(0-9), hyphen(-) and space."
      );
      isValidated = false;
    }

    return isValidated;
  };
  const handleOnNext = () => {
    if (validateForm()) {
      onReportTypeSelectionChange(5, reportName.trim());
    }
  };

  const handlechangeReportName = (value) => {
    setRreportName(value);
    setReportNameError(false);
  };

  return (
    <Dialog
      open={open}
      //onClose={handleClose}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 624,
          maxHeight: 500,
        },
      }}
    >
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          width: 624,
          height: 220,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 30,
            paddingBottom: 14,
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={10} md={10} style={{ paddingLeft: 30 }}>
              <CustomTypography
                text={reportTitle}
                fontfamily="Calibre-SB"
                fontsize="24px"
                fontweight="500"
                lineheight="32px"
                fontcolor="#1F1E1E"
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container style={{ display: "flex", flexDirection: "column" }}>
            {/* Common fileds for Defined and Flexible report */}

            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: 30,
                  paddingRight: 50,
                  marginBottom: 0,
                }}
              >
                <IconTextField
                  id="txtReportNamee"
                  label="Report name"
                  type="text"
                  min={""}
                  max={""}
                  textfieldValue={reportName}
                  callbackFunction={handlechangeReportName}
                  startEl={" "}
                  EndEl={".xlsx"}
                />
              </Grid>
            </Grid>
            {/* Error Message */}
            <Grid container spacing={0}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  paddingLeft: 30,
                  paddingRight: 30,
                  marginBottom: 20,
                }}
              >
                {!reportNameError && (
                  <CustomTypography
                    text={"Click to edit"}
                    fontsize="14px"
                    fontweight="500"
                    lineheight="24px"
                    marginLeft={15}
                    marginTop={1}
                    fontcolor="#898282"
                  />
                )}
                {reportNameError && (
                  <CustomTypography
                    text={reportNameError}
                    fontsize="14px"
                    fontweight="500"
                    lineheight="24px"
                    marginLeft={0}
                    marginTop={10}
                    fontcolor="#FF543E"
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: 9, // : 20,
            width: "100%",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingLeft: "33%", marginBottom: 20 }}
            >
              <Button
                style={{ marginRight: "10px" }}
                className={classes.backButtonClass}
                disabled={false}
                onClick={() => handleOnBack()}
              >
                <CustomTypography
                  text={"Back"}
                  fontsize="16px"
                  fontfamily="Calibre-SB"
                  fontweight="500"
                  lineheight="16px"
                  fontcolor="white"
                  color="rgb(31, 30, 30)"
                />
              </Button>
              <Button
                style={{ zIndex: "1" }}
                className={
                  !enableNext ? classes.disablebuttonClass : classes.buttonClass
                }
                disabled={!enableNext}
                onClick={() => handleOnNext()}
              >
                <CustomTypography
                  text={"Continue"}
                  fontsize="16px"
                  fontfamily="Calibre-SB"
                  fontweight="500"
                  lineheight="16px"
                  fontcolor="white"
                />
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {warningSnackbar && (
        <WarningMessage
          open={warningSnackbar}
          onCloseHandle={() => setWarningSnackbar(false)}
          severity={"warningSnackbar.severity"}
          name={"warningSnackbar.name"}
          message={"warningSnackbar.message"}
        />
      )}
    </Dialog>
  );
};
export default ReportNameModal;
