import React from "react";
import { FormControl, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import styles from "../styles/index";
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    "&:-webkit-autofill": {
      backgroundColor: "#FFFFFF !important;",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1a1a1a",
      },
      "&:hover fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-error fieldset": {
        borderColor: "#FF543E",
      },
      // '& input::placeholder': {
      //   fontSize: '14px',
      //   // fontSize: '20px',

      //   // fontFamily: 'Calibre-R',
      //   // fontWeight: 400,
      //   // lineHeight: 21,
      // },
      // '& input': {
      //   fontSize: '16px',
      //   // fontFamily: 'Calibre-R',
      //   fontWeight: 400,
      //   lineHeight: 20,
      // },
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#003f2d",
    },
  },
  focused: {},
  notchedOutline: {
    "&.MuiOutlinedInput-root.Mui-error": {
      borderColor: "#AB1212",
    },
  },
  inputPlaceholderColor: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#1a1a1a",
      },
      "&:hover fieldset": {
        color: "#205747",
      },
      "&.Mui-focused fieldset": {
        color: "#205747",
      },
    },
  },
}));
const CustomTextField = ({
  value = "",
  info = "",
  onChange,
  id,
  error,
  label = "",
  type = "text",
  InputProps = null,
  disabled = false,
  onBlur = false,
  width = null,
  height = "48px",
  inputPlaceholder = "",
  paddingBottom,
  errorIcon = true,
  maxLength = null,
  multiline = false,
  rows = 1,
  maxRows = "",
  icon = "null",
  variant = "outlined",
  ...props
}) => {
  const style = styles();
  const outlinedInputClasses = useOutlinedInputStyles();
  if (error) {
    props["error"] = true;
  }
  const onChangeValue = (e) => {
    if (props.multiline && props.rows === 1) {
      e.target.value = e.target.value.replace(/\n/g, "");
    }
    onChange(e);
  };
  const handleOnBlur = () => {
    if (onBlur) {
      onBlur(value);
    }
  };
  return (
    <FormControl
      data-qaid="inputtext_formcontrol"
      {...props}
      variant={variant}
      className={style.fullWidth}
    >
      <TextField
        multiline={multiline}
        minRows={rows}
        maxRows={maxRows}
        autoComplete="off"
        disabled={disabled}
        data-qaid="inputtext_outlinedinput"
        variant={variant}
        {...props}
        id={id}
        label={`${label}  `}
        type={type}
        value={value}
        onBlur={handleOnBlur}
        style={{
          width: width !== "null" ? width : "100%",
          height: height,
          backgroundColor: "white",
          fontSize: "20px",
          fontFamily: "Calibre-R",
        }}
        placeholder={inputPlaceholder}
        onChange={onChangeValue}
        className={outlinedInputClasses.root}
        spellCheck={true}
        InputLabelProps={{
          shrink: true,
          style: {
            color: error ? "#FF543E" : "#1A1A1A",
            fontSize: "20px",
            marginTop: "-4px",
            paddingRight: "16px",
            lineHeight: "24px",
            fontFamily: "Calibre-R",
          },
        }}
        inputProps={{
          maxLength: maxLength,
          spellCheck: true,
          lang: "en",
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
          // sx: { fontSize: '14px' },
          ...InputProps,
        }}
        aria-describedby={`${id}-text`}
      />
    </FormControl>
  );
};
export default CustomTextField;
