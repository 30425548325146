import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";

export default function CircularUnderLoad({ svgSize = "100" }) {
  return (
    <div>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          thickness={1}
          size={svgSize}
          style={{ color: "#003F2D" }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 108 108"
          >
            <circle cx="53.367" cy="53.367" r="34.177" fill="#D7F1F9"></circle>
          </svg>
        </Box>
      </Box>
    </div>
  );
}
