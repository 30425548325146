import React from "react";

function DownArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path fill="#000" d="M0 .5l5 5 5-5H0z"></path>
    </svg>
  );
}

export default DownArrowIcon;
