import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PluseIcon from "../icons/pluse_icon";
import DownArrowIcon from "../icons/down_arrow_icon";
import ClearTextIcon from "../icons/clear_text";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    innerHeight: "40",
    fontFamily: "Calibre-R",
    fontSize: "4px",
    fontWeight: "400",
    lineHeight: "21px",
    alignSelf: "stretch",
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000de",
      "& legend": {
        fontSize: "11px",
      },
    },
    "& .MuiInputBase-input": {
      //visibility: "hidden" // setting the search box
      height: "1em",
      fontSize: 12,
    },
    "&:-webkit-autofill": {
      backgroundColor: "#FFFFFF !important;",
    },
    "& .MuiOutlinedInput-root": {
      padding: "5px 12px!important",
      "& fieldset": {
        borderColor: "#1a1a1a",
      },
      "& .MuiAutocomplete-tag": {
        backgroundColor: "rgba(0, 63, 45, 0.20)",
        borderRadius: 15,
        fontSize: 11,
        height: 27,
      },
      "& span.MuiAutocomplete-tag": {
        backgroundColor: "#fff",
        //Color: "#000",
        borderRadius: 15,
        fontSize: 14,
        lineHeight: "27px",
      },
      "& .MuiChip-label": {
        color: "black",
      },
      "& .MuiSvgIcon-root": {
        height: "0.7em",
      },
      "&:hover fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-error fieldset": {
        borderColor: "#FF543E",
      },
    },
  },
  // dense: {
  //   marginTop: theme.spacing(2),
  // },
  // menu: {
  //   width: 100,
  // },
  root: {
    "&:hover": {
      borderColor: "#c4c4c4",
    },
    "&$focused ": {
      borderColor: "#c4c4c4",
    },
    "&.Mui-error": {
      borderColor: "#AB1212",
    },
  },
}));
export default function MultiSelect({
  options,
  title,
  preselectedValue = null,
  isMultiple = true,
  handleOnChange,
  searchPlaceHolder = "Search",
}) {
  const [selectedValue, setSelectedValue] = useState(isMultiple ? [] : null);
  const classes = useStyles();
  const handleInputChange = (event, value) => {
    setSelectedValue(value);
    handleOnChange(value);
  };

  useEffect(() => {
    var selVal =
      selectedValue == null || selectedValue.length == 0
        ? preselectedValue
        : selectedValue;
    setSelectedValue(selVal);
  }, []);

  var filteredOptions = options;
  if (selectedValue != null) {
    if (isMultiple)
      filteredOptions = options.filter((ad) =>
        selectedValue.every((fd) => fd !== ad)
      );
  }
  return (
    <Autocomplete
      limitTags={4}
      value={selectedValue}
      ListboxProps={{
        sx: { fontSize: 14, fontFamily: "Calibre-R" },
      }}
      className={classes.textField}
      multiple={isMultiple}
      options={filteredOptions}
      getOptionLabel={(option) => option}
      disableCloseOnSelect={false}
      filterSelectedOptions
      onChange={handleInputChange}
      style={{ margin: 0 }}
      clearIcon={<ClearTextIcon height="9" />}
      popupIcon={
        isMultiple ? <PluseIcon height="9" /> : <DownArrowIcon height="9" />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={title}
          placeholder={searchPlaceHolder}
          InputLabelProps={{
            shrink: true,
            style: {
              color: "#000",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: 18,
              lineHeight: "18px",
              fontFamily: "Calibre-R",
              top: -3,
            },
          }}
        />
      )}
    />
  );
}
