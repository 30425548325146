import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LeftDrawer from "./left_drawer";
import DashboardIcon from "../../icons/dashboard_icon";
import DocumentIcon from "../../icons/document_icon";
import HelpIcon from "../../icons/help_icon";
import clsx from "clsx";
import {
  Box,
  ListItemIcon,
  Drawer,
  ListItemText,
  Hidden,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 65,
  },
  toolbar: {
    backgroundColor: "#fafafa",
  },
  title: {
    fontFamily: "Calibre-R",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
  },
  menuButton: {
    fontFamily: "Calibre-R",
    height: 50,
    "&.active": {
      backgroundColor: "#ebf0ef",
      color: "#003F2D",
    },
  },
  icon: {
    minWidth: 35,
    width: 20,
    height: "100%",
    color: "#004C60",
    marginRight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&.active": {
      borderBottom: "2px solid #051D33",
    },
  },
}));
const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const isMapNavigation = useSelector(
    (state) => state.mainReducer.isMapNavigation
  );
  const [open, setOpen] = useState(null);
  const [isDrawerOpen, toggleDrawer] = useState(false);
  const [itemsList, setItemList] = useState([
    {
      id: 1,
      href: "/dashboard",
      icon: DashboardIcon,
      name: "Dashboard",
    },
    // {
    //   id: 1,
    //   href: "/PreviousReports",
    //   icon: DocumentIcon,
    //   name: "Previous Reports",
    // },
    {
      id: 1,
      href: "/help",
      icon: HelpIcon,
      name: "Help",
    },
  ]);
  useEffect(() => {
    if (isMapNavigation) {
      setItemList([
        {
          id: 1,
          href: "/dashboard",
          icon: DashboardIcon,
          name: "Dashboard",
        },
      ]);
    }
  }, [isMapNavigation]);
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box>
        <List
          style={{
            color: "#1A1A1A",
            paddingTop: 60,
            paddingLeft: 7,
            paddingRight: 7,
          }}
          component="nav"
        >
          {itemsList.map((item) => (
            <NavItem
              key={item.name}
              item={item}
              open={open}
              isDrawerOpen={isDrawerOpen}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );
  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <LeftDrawer
          classes1={classes}
          toggleDrawer={() => {
            toggleDrawer(!isDrawerOpen);
            setOpen(null);
          }}
          open={isDrawerOpen}
        >
          {content}
        </LeftDrawer>
      </Hidden>
    </>
  );
};

const NavItem = ({ item, handleClick, open, isDrawerOpen }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const NavIcon = item.icon;
  const getClassNames = () => {
    if (item.href === location.pathname) {
      if (isDrawerOpen) {
        return clsx(classes.menuButton, "active");
      } else {
        return clsx(classes.menuButton, "active", "open");
      }
    } else return clsx(classes.menuButton);
  };
  const handleOnClikListItem = () => {
    //setting local storage data to empty and naviagate to selected Item
    localStorage.setItem("cache_data", JSON.stringify({}));
    navigate(item.href);
  };
  return (
    <>
      <ListItem
        button
        className={getClassNames()}
        style={{ color: "##1A1A1A", paddingLeft: 18, paddingTop: 12 }}
        onClick={() => handleOnClikListItem()}
      >
        <ListItemIcon
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            minWidth: "40px",
          }}
        >
          <span>
            <NavIcon
              color={item.href === location.pathname ? "#003F2D" : "#1A1A1A"}
              width={20}
              height={20}
            />
          </span>
        </ListItemIcon>
        {isDrawerOpen && (
          <ListItemText
            classes={{ primary: classes.title }}
            primary={item.name}
            style={{ fontWeight: "400", fontFamily: "Calibre-R" }}
          />
        )}
      </ListItem>
    </>
  );
};
NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
