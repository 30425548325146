/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import NavBar from "./navbar_drawer";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
    paddingBottom: 41,
  },
}));
const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const classes = useStyles(isMobileNavOpen);
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const hubUser = useSelector((state) => state.mainReducer.email);
  useEffect(() => {
    if (!isAuthenticated && !hubUser) {
      navigate("/");
    }
  }, []);
  return (
    <div className={classes.root}>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
