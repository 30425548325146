/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { Card, CardContent, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTypography from "../utils/text";
import PluseIcon from "../icons/pluse_icon";
import WarningMessage from "../components/warning_snackbar";
import SnackBar from "../components/SnackBar";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import ReportSelectionModal from "../components/report_selection_modal";
import DownloadReportModal from "../components/download_report_modal";
import RenderReportModal from "../components/render_report_modal";
import ReportNameModal from "../components/report_name_modal";
import DownloadIcon from "../icons/download_icon";
import DocumentIcon from "../icons/document_icon";
import { downloadFile } from "../utils/utilities";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { apiEndPoints, reportParamters } from "../apilayer/staticData";
import moment from "moment";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontFamily: "Calibre-R",
    fontWeight: "bold",
    backgroundColor: "inherit !important",
    fontSize: "14px",
    lineHeight: "21px",
  },
}));

/*
report stage: 
-1 : hide all popups 
 0 : open selection
 1 : open Defined
 2 : open flexible
 3 : open report name modal
 4 : open download
*/
const Dashboard = () => {
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [snackbar, setSnackbar] = useState(null);
  const [reportStage, setReportStage] = useState(-1);
  const [showPreviusReportOption, setShowPreviusReportOption] = useState(false) ;
  const [dataRefershObject, setDataRefershObject] = useState({
    callCounter: 0,
    last_refresh: "loading...",
    next_refresh: "loading...",
  });
  const axiosPrivate = useAxiosPrivate();
  const [isLodding, setIsLodding] = useState(false);
  const [reportParams, setReportParams] = useState(reportParamters);

  useEffect(() => {
    if (dataRefershObject.callCounter === 0) {
      fetchDataRefreshInfo();
    } else {
      const timer = setTimeout(() => {
        fetchDataRefreshInfo();
      }, 60000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dataRefershObject.callCounter]);

  useEffect(() => {
    let cacheData = {};
    if (localStorage?.getItem("cache_data")) {
      cacheData = JSON.parse(localStorage?.getItem("cache_data"));
    }
    if (cacheData) {
      if (cacheData?.step === "step1") {
        //setOpen(true);
      }
    }
  }, []);

  const fetchDataRefreshInfo = async () => {
    try {
      // var dtLastRefresh = moment("19/01/2024 15:10", "DD/MM/YYYY hh:mm");
      var isPast = moment(
        dataRefershObject.next_refresh,
        "DD/MM/YYYY hh:mm"
      ).isBefore();
      var isUpcoming = moment(
        dataRefershObject.next_refresh,
        "DD/MM/YYYY hh:mm"
      ).isAfter();
      if (
        dataRefershObject.callCounter === 0 ||
        moment(dataRefershObject.next_refresh, "DD/MM/YYYY hh:mm").isBefore()
      ) {
        setIsLodding(true);
        const data = await axiosPrivate.get(apiEndPoints.GetDataRefershInfo);
        if (data.status === 200) {
          setDataRefershObject({
            callCounter: dataRefershObject.callCounter + 1,
            last_refresh: data.data.result.last_refresh,
            next_refresh: data.data.result.next_refresh,
          });
          //setDataRefershObject(data.data.result);
        }
      } else {
        setDataRefershObject((prevState) => ({
          ...prevState,
          callCounter: prevState.callCounter + 1,
        }));
      }
    } catch (error) {
      console.error("filed to load the locations...", error);
    } finally {
      console.log("call completed..");
      setIsLodding(false);
    }
  };

  const handleDownloadPdf = () => {
    downloadFile(
      "User Stats Report Getting Started Guide",
      process.env.PUBLIC_URL + "/User Stats Report_Getting Started Guide.pdf"
    );
  };

  const handleClickOpen = () => {
    //dispatch({ type: "CLEAR_SEARCH_OBJECT" });
    setReportStage(0);
  };

  const handleOnCloseReportModals = () => {
    setReportStage(-1);
    setReportParams(reportParamters);
  };

  const onReportTypeSelectionChange = (selectedReportType, objReportParam) => {
    setWarningSnackbar(null);
    setReportStage(selectedReportType);
    if (selectedReportType === 0) {
      setReportParams(reportParamters);
    } else if (selectedReportType <= 4) {
      setReportParams(objReportParam);
    } else if (selectedReportType === 5) {
      var selectReportParams = reportParams;
      selectReportParams.reportName = objReportParam;
      setReportParams(selectReportParams);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#EEF1F1",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 26,
        }}
      >
        <Grid item md={7}>
          <CustomTypography
            text="Download stats report"
            fontsize="36px"
            fontweight="400"
            lineheight="40px"
            fontcolor="#1A1A1A"
            marginTop="40px"
          />
        </Grid>
        <Grid
          item
          md={5}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginTop: "45px",
            paddingRight: 15,
          }}
        >
          <CustomTypography
            text={"Last refresh: " + dataRefershObject.last_refresh}
            fontsize="18px"
            fontweight="600"
            lineheight="20px"
            fontcolor="#1A1A1A"
          />
          <CustomTypography
            text={"Next refresh: " + dataRefershObject.next_refresh}
            fontsize="18px"
            fontweight="600"
            lineheight="20px"
            fontcolor="#1A1A1A"
          />{" "}
          <CustomTypography
            text={"Is Data Loaded: " + dataRefershObject.callCounter}
            fontsize="15"
            fontweight="400"
            fontcolor="#1A1A1A"
            marginTop="4px"
            display="none"
          />
        </Grid>
      </Grid>
      <Grid
        container
        style={{ display: "flex", flexDirection: "row", marginTop: "8%" }}
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            textAlign: "center",
            height: "20vh",
            position: "relative",
            marginLeft: "32%",
          }}
        >
          <Card
            onClick={handleClickOpen}
            style={{
              cursor: "pointer",
              minHeight: 120,
              borderRadius: 10,
              backgroundColor: "#d7dfdd",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
              boxShadow: "none",
              width: 500,
              // transform: " translate(-50%, -50%)",
            }}
          >
            <CardContent
              style={{
                paddingTop: 25,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PluseIcon />
              <CustomTypography
                text="Generate new report"
                fontfamily="Calibre-SB"
                fontsize="16px"
                fontweight="500"
                lineheight="16px"
                fontcolor="#003F2D"
                marginLeft="10px"
              />
            </CardContent>
          </Card>
        </Grid>
        {showPreviusReportOption && (
          <Card
            // onClick={handleDownloadExcel}
            style={{
              cursor: "pointer",
              minHeight: 120,
              borderRadius: 10,
              backgroundColor: "#d7dfdd",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
              boxShadow: "none",
              width: 500,
            }}
          >
            <CardContent
              style={{
                paddingTop: 20,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DownloadIcon />
              <CustomTypography
                text="Download Previous Report"
                fontfamily="Calibre-SB"
                fontsize="16px"
                fontweight="500"
                lineheight="16px"
                fontcolor="#003F2D"
                marginLeft="10px"
              />
            </CardContent>
          </Card>
        )}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            textAlign: "center",
            height: "20vh",
            position: "relative",
            marginLeft: "32%",
          }}
        >
          <Card
            onClick={handleDownloadPdf}
            style={{
              cursor: "pointer",
              minHeight: 120,
              borderRadius: 10,
              backgroundColor: "#d7dfdd",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 40,
              boxShadow: "none",
              width: 500,
            }}
          >
            <CardContent
              style={{
                paddingTop: 20,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DocumentIcon />
              <CustomTypography
                text="How to use the tool"
                fontfamily="Calibre-SB"
                fontsize="16px"
                fontweight="500"
                lineheight="16px"
                fontcolor="#003F2D"
                marginLeft="10px"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* PGOEL */}
      {reportStage === 0 && (
        <ReportSelectionModal
          open={reportStage === 0}
          handleClose={handleOnCloseReportModals}
          onReportTypeSelectionChange={onReportTypeSelectionChange}
        />
      )}

      {(reportStage === 1 || reportStage === 2 || reportStage === 3) && (
        <RenderReportModal
          reportType={reportStage}
          reportParams={reportParams}
          open={4 > reportStage > 0}
          handleClose={handleOnCloseReportModals}
          onReportTypeSelectionChange={onReportTypeSelectionChange}
        />
      )}
      {reportStage === 4 && (
        <ReportNameModal
          reportType={reportParams.reportType}
          reportParams={reportParams}
          open={reportStage === 4}
          handleClose={handleOnCloseReportModals}
          onReportTypeSelectionChange={onReportTypeSelectionChange}
        />
      )}
      {reportStage === 5 && (
        <DownloadReportModal
          reportParams={reportParams}
          open={reportStage === 5}
          handleClose={handleOnCloseReportModals}
          onReportTypeSelectionChange={onReportTypeSelectionChange}
        />
      )}

      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
    </div>
  );
};
export default Dashboard;
