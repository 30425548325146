/* eslint-disable react-hooks/exhaustive-deps */
import "react-perfect-scrollbar/dist/css/styles.css";
import React, { useEffect, useState } from "react";
import { useRoutes, useLocation } from "react-router-dom";
import "./App.css";
import routes from "./routes";
import "mapbox-gl/dist/mapbox-gl.css";
import { Provider } from "react-redux";
import StoreConfig from "./redux/store";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { datadogRum } from "@datadog/browser-rum";
import WarningMessagelert from "./components/warning_snackbar";
const pca = new PublicClientApplication(msalConfig);

//Datadog Rum Code starts
// datadogRum.init({
//   applicationId: "09f95e43-d3d7-4f96-b6a2-fc52e1349394",
//   clientToken: "pubba50908a1178e728eb9ac84a905ef21a",
//   site: "datadoghq.com",
//   service: "uk-data-platform",

//   // Specify a version number to identify the deployed version of your application in Datadog
//   version: "1.0.0",
//   sampleRate: 100,
//   sessionReplaySampleRate: 20,
//   trackInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: "mask-user-input",
// });

// datadogRum.startSessionReplayRecording();
//Datadog Rum Code Ends

function ChildComponet() {
  const routing = useRoutes(routes);
  const location = useLocation();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  useEffect(() => {
    if (StoreConfig.store.getState().mainReducer.isOauthTokenExpired) {
      setWarningSnackbar({
        name: "OAuth access token expired:",
        message: "Please login again...",
        severity: "error",
      });
    }
  }, [
    StoreConfig.store.getState().mainReducer.isOauthTokenExpired,
    StoreConfig.store.getState().mainReducer,
  ]);
  return (
    <Provider store={StoreConfig.store}>
      <>
        <div
          className="App"
          style={{
            backgroundColor: "rgb(238, 241, 241)",
            height: "100vh",
          }}
        >
          {routing}
        </div>
        {warningSnackbar && (
          <WarningMessagelert
            open={true}
            setOpen={() => setWarningSnackbar(null)}
            severity={warningSnackbar.severity}
            name={warningSnackbar.name}
            message={warningSnackbar.message}
          />
        )}
      </>
    </Provider>
  );
}
function App() {
  return (
    <MsalProvider instance={pca}>
      <ChildComponet />
    </MsalProvider>
  );
}

export default App;
