/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import {
  Dialog,
  Button,
  makeStyles,
  Grid,
  FormControl,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@material-ui/core";
import CloseIcon from "../icons/close_icon";
import CustomTypography from "../utils/text";
import WarningMessage from "./warning_snackbar";
import Typography from "@mui/material/Typography";
import { reportParamters } from "../apilayer/staticData";
const useStyles = makeStyles({
  buttonClass: {
    "&:hover": {
      color: "white",
      background: "#003F2D",
    },
    background: "#003F2D",
    color: "white",
    height: 32,
    width: 180,
  },
  disablebuttonClass: {
    "&:hover": {
      color: "white",
      background: "#d4d7d7",
    },
    background: "#d4d7d7",
    height: 32,
    width: 180,
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    overflow: "hidden",
    maxWidth: 624,
  },
});
const ReportSelectionModal = ({
  open = false,
  handleClose,
  onReportTypeSelectionChange,
}) => {
  const classes = useStyles();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [reportType, setReportType] = useState(0);

  const handleOnNext = () => {
    let reportParams = reportParamters;
    reportParams.paramEntered = false;
    reportParams.reportType = reportType;
    reportParams.marketGroup = "";
    reportParams.subMarket = "";
    reportParams.district = "";
    reportParams.neightbourhood = "";
    reportParams.dateFrom = "";
    reportParams.dateTo = "";
    reportParams.break = 1; //default value
    reportParams.minPropSize = "";
    reportParams.maxPropSize = "";
    onReportTypeSelectionChange(reportType, reportParams);
  };

  const onReportTypeChange = (event) => {
    event.persist();
    setReportType(parseInt(event.target.value));
  };

  return (
    <Dialog
      open={open}
      //onClose={handleClose}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 624,
          maxHeight: 500,
        },
      }}
    >
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          width: 624,
          height: 350,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 30,
            paddingBottom: 14,
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={10} md={10} style={{ paddingLeft: 30 }}>
              <CustomTypography
                text={"Generate new report"}
                fontfamily="Calibre-SB"
                fontsize="24px"
                fontweight="500"
                lineheight="32px"
                fontcolor="#1F1E1E"
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 0,
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 10,
              }}
            >
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#1a1a1a99",
                    fontsize: 14,
                    fontFamily: "Calibre-R",
                    display: "flex",
                    justifyContent: "flex-start",
                    marginBottom: 20,
                  }}
                >
                  Choose market input
                </FormLabel>
                <RadioGroup
                  onChange={onReportTypeChange}
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="0"
                  name="radio-buttons-group"
                  style={{ paddingLeft: 10 }}
                >
                  <FormControlLabel
                    style={
                      reportType === 1
                        ? {
                            backgroundColor: "#1d85353b",
                            borderRadius: 5,
                            width: 250,
                          }
                        : null
                    }
                    value="1"
                    control={<Radio color={"default"} />}
                    label="Defined market groups"
                  />
                  {/* <FormControlLabel
                    style={
                      reportType == 2
                        ? {
                            backgroundColor: "#1d85353b",
                            borderRadius: 5,
                            width: 250,
                          }
                        : null
                    }
                    value="2"
                    control={<Radio color="default" />}
                    label="Flexible market selections"
                  /> */}
                  <FormControlLabel
                  style={
                    reportType === 3
                      ? {
                          backgroundColor: "#1d85353b",
                          borderRadius: 5,
                          width: 250,
                        }
                      : null
                  }
                  value="3"
                  control={<Radio color="default" />}
                  label="Flexible market selections"
                />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 32 }}
            >
              <Typography
                variant="body2"
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  color: "#1a1a1a99",
                  fontsize: 14,
                  fontFamily: "Calibre-R",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                {reportType === 0
                  ? "Please select report type."
                  : reportType === 1
                  ? "This option provides preset market selections."
                  : "This option provides the flexibility of selecting one or more market, sub-market, district and neighbourhood."}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: 20,
            width: "100%",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 30,
              }}
            >
              <Button
                disabled={reportType <= 0}
                className={
                  reportType <= 0
                    ? classes.disablebuttonClass
                    : classes.buttonClass
                }
                onClick={() => handleOnNext()}
              >
                <CustomTypography
                  text={"Continue"}
                  fontsize="16px"
                  fontfamily="Calibre-SB"
                  fontweight="500"
                  lineheight="16px"
                  fontcolor="white"
                />
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Dialog>
  );
};
export default ReportSelectionModal;
