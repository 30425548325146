import React from "react";

function DocumentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        fill="#003F2D"
        d="M4.603 6h-2v14c0 1.1.9 2 2 2h14v-2h-14V6zm16-4h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14h-12V4h12v12zm-10-7h8v2h-8V9zm0 3h4v2h-4v-2zm0-6h8v2h-8V6z"
      ></path>
    </svg>
  );
}

export default DocumentIcon;
