import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const textFieldOutlineStyle = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000000de",
    "& legend": {
      fontSize: "15px",
    },
  },
  "& .MuiFormLabel-root": {},
  "& .MuiInputBase-root": {
    fontFamily: "Calibre-R",
    fontSize: 14,
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#000000de",
    },
  },
  "& .MuiInputAdornment-root": { marginRight: 0, "& p": { fontSize: 12 } },
};

const IconTextField = ({
  id,
  type,
  label,
  min,
  max,
  textfieldValue,
  callbackFunction,
  startEl,
  EndEl,
}) => {
  return (
    <TextField
      key={id}
      id={id}
      label={label}
      //defaultValue={min}
      type={type}
      value={textfieldValue}
      sx={textFieldOutlineStyle}
      style={{
        width: "100%",
        borderColor: "black",
      }}
      onKeyPress={(event) => {
        if (type === "number") {
          if (event?.key === "e" || event?.key === "+") {
            event.preventDefault();
          }
          if (event.target.value.length === 0 && event?.key === "-") {
            event.target.value = "";
          }
          if (event.target.value.length > 0 && event?.key === "-") {
            event.preventDefault();
          }
        }
      }}
      onPaste={(e) => {
        if (type === "number") {
          const reg = "^[-]?[0-9]*\\.?[0-9]*$";
          const current = e.target.value;
          if (!current) {
            e.target.value = "";
          }
          if (
            !(current + e.clipboardData.getData("Text")).match(reg) &&
            e.clipboardData.getData("Text").match(reg)
          ) {
            e.target.value = "";
          }
          if (!e.clipboardData.getData("Text").match(reg)) {
            e.preventDefault();
          }
        }
      }}
      onChange={(e) => {
        var value = e.target.value;
        if (type === "number" && value !== "") {
          value = value.replace("-", "");
          //value = id == "txtMaxPropSize" ? max : min;
          //if (e.target.value != "") {
          //value = parseInt(e.target.value);
          //if (value > max) value = Number.MAX_SAFE_INTEGER;
          //if (value < min) value = min;
          //}
          value = parseInt(value.slice(0, 10));
        } else {
          value = value.slice(0, 100);
        }
        callbackFunction(value);
      }}
      InputProps={{
        inputProps: type === "number" ? { max: 100, min: 0 } : {},
        style: { fontSize: 14 },
        startAdornment: startEl ? (
          <InputAdornment position="start">{startEl}</InputAdornment>
        ) : null,
        endAdornment: <InputAdornment position="end">{EndEl}</InputAdornment>,
      }}
      InputLabelProps={{
        sx: {
          fontSize: "20px",
          fontFamily: "Calibre-R",
          color: "#000000de!important",
          top: -5,
          left: 0,
          zIndex: 0,
        },
      }}
    />
  );
};

export default IconTextField;
