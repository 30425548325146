/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import OAuthError from "../utils/errorMessages";
import StoreConfig from "../redux/store/index";

const useAxiosPrivate = () => {
  const env = window.location.hostname;
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [, setWarningSnackbar] = useState(null);
  const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  });
  const { store } = StoreConfig;

  const refreshToken = async () => {
    const responseData = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    const token = responseData.accessToken;
    const decoded = jwt_decode(token);
    if (decoded?.unique_name) {
      dispatch({
        type: "SET_USER_EMAIL",
        payload: decoded?.unique_name,
      });
      dispatch({
        type: "SET_OAUTH_TOKEN",
        payload: responseData.accessToken,
      });
    }
    console.log("Acquired New Token...........!");
    return token;
  };

  const oauth_token =
    env === "localhost"
      ? process.env.REACT_APP_SSO_TOKEN
      : store.getState().mainReducer.oauth_token;
  const requestIntercept = axiosPrivate.interceptors.request.use(
    (config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = oauth_token;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  useEffect(() => {
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          if (window.location.hostname === "localhost") {
            setWarningSnackbar(OAuthError);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
            return Promise.reject(error);
          }
          prevRequest.sent = true;
          const token = await refreshToken();
          prevRequest.headers["Authorization"] = token;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [isAuthenticated]);

  return axiosPrivate;
};

export default useAxiosPrivate;
