
/* eslint-disable default-case */
import moment from "moment";
export function getCapitalized(name) {
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const downloadFile = (name, template) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = template;
  link.click();
};

export const convertDateFormate = (inputDate) => {
  const dateObj = new Date(inputDate);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = dateObj
    .toLocaleDateString("en-GB", options)
    .replace(/\//g, ".");
  return formattedDate;
};

export function formateDate(inputDate) {
  const date = new Date(inputDate);
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${month} ${year}`;
}

// this will convert from 26/08/2023 10:23 AM to Sat 26 Aug, 10:23 pm
export const convertDate = (inputDate) => {
  const dateObj = moment(inputDate, "DD/MM/YYYY hh:mm A");
  const formattedDate = dateObj.format("ddd D MMM, hh:mm a");
  return formattedDate;
};

export const colorCode = (rating) => {
  switch (rating) {
    case "A+":
      return "#41D23E";
    case "A":
      return "#62D1A9";
    case "B":
      return "#95D28A";
    case "C":
      return "#C4CF7D";
    case "D":
      return "#EEDD45";
    case "E":
      return "#EBB371";
    case "F":
      return "#FF8D23";
    case "G":
      return "#FF3B20";
    case "None":
      return "#778F9C";
  }
};

export const numberWithCommas = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
};
