export const cssStatics = {
  fontSize: 14,
  textAlign: "center",
  bold_fontWeight: 800,
};
export function getBreakOptions() {
  return [
    { id: 1, name: "Month" },
    { id: 3, name: "Quarter" },
    { id: 6, name: "Half" },
    { id: 12, name: "Year" },
  ];
}

// object.js
export const reportParamters = {
  reportName : '',
  paramEntered: false,
  reportType: 0,
  marketGroup: '',
  subMarket: [],
  district: [],
  neighbourhood: [],
  dateFrom: '',
  dateTo: '',
  break: 1,
  minPropSize: 0,
  maxPropSize: 9999999
  // ... other properties
};

export const localStorageKey = {
  MarketGroupsData: "marketGroupsData",
  LocationDropDownsData: "locationDropDownsData",
};

export const apiEndPoints = {
  MarketGroups: "/market_groups",
  LocationDropDowns: "/location_dropdowns",
  QueryDefinedReport: "/query_predefined",
  QueryFlexibleReport: "/query_flexible",
  GetDataRefershInfo: "/database_refresh_timestamps",
  LocationDropDownsHierarchical: "/location_dropdowns_hierarchical"
};
