/* eslint-disable no-fallthrough */
/* eslint-disable array-callback-return */
import { combineReducers } from "redux";
const intialState = {
  locationData: [],
  hkey: "",
  hwgtkey: "",
  selectedProjectStatus: "",
  projectName: "",
  compnayId: "",
  projectId: "",
  selectedProjectCompletedDate: "",
  email: "",
  propertyData: [],
  projectsData: [],
  oauth_token: "",
  isOauthTokenExpired: false,
  industryCode: [],
  selectedIndividualPropertyData: [],
  isIndividualProperty: false,
  sortByOption: "Last viewed",
  showByOption: "All",
  isUserClosedAlertBar: false,
};
function mainReducer(state = intialState, action) {
  switch (action.type) {
    case "SET_OAUTH_TOKEN":
      return { ...state, oauth_token: action.payload };
    case "SET_INDUSTRY_CODE":
      return { ...state, industryCode: action.payload };
    case "SET_COMPANY_ID":
      return { ...state, compnayId: action.payload };
    case "SET_PROJECT_ID":
      return { ...state, projectId: action.payload };
    case "SET_OAUTH_TOKEN_EXPIRED":
      return { ...state, isOauthTokenExpired: action.payload };
    case "SELECTED_PROJECT_STATUS":
      return {
        ...state,
        selectedProjectStatus: action.payload,
        isUserClosedAlertBar: false,
      };
    case "SET_PROJECT_NAME":
      return {
        ...state,
        projectName: action.payload,
      };
    case "SELECTED_PROJECT_COMPLETED_DATE":
      return { ...state, selectedProjectCompletedDate: action.payload };
    case "CLEAR_DATA":
      return { ...state, serachObject: {}, hkey: "", hwgtkey: "", hubUser: "" };
    case "SET_USER_EMAIL":
      return { ...state, email: action.payload };
    case "SET_SORT_BY_OPTION":
      return { ...state, sortByOption: action.payload };
    case "SET_SHOW_BY_OPTION":
      return { ...state, showByOption: action.payload };
    default:
      return state;
  }
}

const ReformatLocationData = (data) => {
  var array = [];
  data?.map((item, index) => {
    array.push({ id: index + 1, ...item });
  });
  return array;
};
const rootReducer = combineReducers({
  mainReducer,
});

export default rootReducer;
