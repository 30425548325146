import React, { createContext, useContext, useEffect, useState } from "react";

const VaultContext = createContext();

export const useVault = () => useContext(VaultContext);

export const VaultProvider = ({ children }) => {
  const [secret] = useState(null);

  useEffect(() => {
    const fetchSecret = async () => {
      try {
        // const response = await fetch(
        //   "https://lockbox.gcso.cbre.com/v1/secret/ukplatform/dev/kv/epc",
        //   {
        //     method: "GET",
        //     headers: {
        //       "X-Vault-Token": process.env.REACT_APP_VAULT_TOKEN,
        //     },
        //   }
        // );
        // console.log("response form the hashi corp vault....!", response);
        // const data = await response.json();
        // console.log("response data form the hashi corp vault....!", data);
        // setSecret(data.data);
      } catch (error) {
        console.error("Failed to fetch secret from Vault:", error);
      }
    };
    fetchSecret();
  }, []);
  return (
    <VaultContext.Provider value={{ secret }}>{children}</VaultContext.Provider>
  );
};
