/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../img/background.jpeg";
import CbreIcon from "../icons/cbre_icon";
import CustomTypography from "../utils/text";
import CustomTextField from "../components/custom_textfield";
import { useDispatch } from "react-redux";
import ErrorModal from "../components/error_componet";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useIsAuthenticated } from "@azure/msal-react";
import jwt_decode from "jwt-decode";
import LoadingOverlayComponent from "../components/lodding_component";
import WarningMessage from "../components/warning_snackbar";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import CircularProgress from "@mui/material/CircularProgress";
const useStyles = makeStyles({
  btn: {
    "&:hover": {
      color: "white",
      background: "#003F2D",
      border: "1px solid #0000001a",
    },
    background: "#003F2D",
    color: "white",
  },
  btn_text: {
    "&:hover": {
      color: "red",
    },
  },
});
const Login = () => {
  const { instance, accounts } = useMsal();
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [noUserError, setNoUserError] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [isLodding, setIsLodding] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const env = window.location.hostname;
  const handleSubmit = async () => {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userName) &&
      userName
    ) {
      setLoginLoading(true);
      validateUser(userName);
    } else {
      setErrorMessage("Invalid username");
    }
  };

  const handleOnChangeUserName = (value) => {
    setUserName(value);
  };

  const handleSubmitSSO = async () => {
    setErrorMessage("");
    await instance.loginRedirect(loginRequest).catch((error) => {
      console.log("loginRedirect error....!", error);
    });
  };

  useEffect(() => {
    console.log("Is user authenticated....!", isAuthenticated);
    if (isAuthenticated) {
      async function refreshToken() {
        const responseData = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const token = responseData.accessToken;
        const decoded = jwt_decode(token);
        const name = decoded?.unique_name;
        if (decoded?.unique_name) {
          dispatch({
            type: "SET_USER_EMAIL",
            payload: decoded?.unique_name,
          });
          dispatch({
            type: "SET_OAUTH_TOKEN",
            payload: responseData.accessToken,
          });
        }
        console.log("Acquired New Token...........!");
        return { name, token };
      }
      setLoginLoading(true);
      refreshToken().then((resp) => {
        if (resp.token) {
          validateUser(resp.name, resp.token);
        }
      });
    }
  }, [isAuthenticated]);

  const validateUser = async (userName, token) => {
    try {
      // This is to check SSO login
      const response = await axiosPrivate.post("/login", null, {
        headers: { Authorization: token },
      });
      if (response.data.status === "success") {
        dispatch({ type: "SET_USER_EMAIL", payload: userName });
        setIsLodding(false);
        setLoginLoading(false);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(error);
      setIsLodding(false);
      setLoginLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        overFlow: "hidden",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Grid container>
        <Grid item xs={9} md={9} sm={7} lg={9} xl={9}>
          <img
            className="tile-image"
            src={backgroundImage}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              minHeight: "100%",
              verticalAlign: "middle",
              objectFit: "cover",
            }}
            alt="logo"
          />
        </Grid>
        <Grid
          item
          xs={3}
          md={3}
          sm={5}
          lg={3}
          xl={3}
          style={{ backgroundColor: "white" }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              padding: 32,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <CbreIcon />
              <CustomTypography
                text="Sign in"
                fontfamily="Calibre-R"
                fontsize="24px"
                fontweight="500"
                lineheight="32px"
                fontcolor="#000000"
                marginTop="40px"
              />
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              >
                <CustomTypography
                  text="Don’t have an account? "
                  fontsize="16px"
                  fontweight="400"
                  lineheight="20px"
                  fontcolor="#000000"
                  marginTop="6px"
                />
                {/* <CustomTypography
                  text=" Get one."
                  fontsize="16px"
                  fontweight="400"
                  lineheight="20px"
                  fontcolor="#003F2D"
                  marginTop='6px'
                  textDecoration='underline'
                  marginLeft={5}
                /> */}
              </Grid>
              {errorMessage && (
                <ErrorModal
                  errorMessage={errorMessage}
                  handleClose={() => {
                    setNoUserError(false);
                    setErrorMessage("");
                  }}
                />
              )}
              {env === "localhost" && !noUserError && (
                <CustomTextField
                  style={{ marginTop: 20 }}
                  id="outlined-name"
                  label="User Name"
                  value={userName}
                  required={true}
                  inputPlaceholder="User Name"
                  onChange={(event) =>
                    handleOnChangeUserName(event.target.value)
                  }
                  type="email"
                  variant="outlined"
                  error={errorMessage}
                />
              )}
              {env === "localhost" && (
                <Button
                  size="large"
                  type="submit"
                  className={classes.btn}
                  variant="contained"
                  style={{
                    marginTop: 20,
                    fontWeight: 700,
                    fontSize: 18,
                    height: 40,
                  }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {loginLoading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                      sx={{ color: "#FFFFFF", marginRight: "20px" }}
                    />
                  )}
                  <CustomTypography
                    className={classes.btn_text}
                    text="Sign in with SSO"
                    fontsize="16px"
                    fontweight="500"
                    lineheight="16px"
                    fontcolor="#FFFFFF"
                    textundelineOffset="0.2 em"
                  />
                </Button>
              )}
              {env !== "localhost" && (
                <div>
                  <Grid
                    style={{
                      borderTop: "1px solid #959595",
                      marginTop: 32,
                      marginBottom: 32,
                    }}
                  ></Grid>

                  <Button
                    size="large"
                    type="submit"
                    className={classes.btn}
                    variant="contained"
                    style={{
                      fontWeight: 700,
                      fontSize: 18,
                      height: 40,
                      width: "100%",
                    }}
                    onClick={handleSubmitSSO}
                  >
                    {loginLoading && (
                      <CircularProgress
                        size={25}
                        thickness={2}
                        sx={{ color: "#FFFFFF", marginRight: "20px" }}
                      />
                    )}
                    <CustomTypography
                      className={classes.btn_text}
                      text="Sign in with SSO"
                      fontsize="16px"
                      fontweight="500"
                      lineheight="16px"
                      fontcolor="#FFFFFF"
                      textundelineOffset="0.2 em"
                    />
                  </Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
      {isLodding && <LoadingOverlayComponent open={isLodding} />}
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </div>
  );
};
export default Login;
