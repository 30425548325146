import React from "react";
import { Grid } from "@material-ui/core";
import ErrorIcon from "../icons/error_icon";
import CustomTypography from "../utils/text";
import CloseIcon from "../icons/close_icon";
const ErrorModal = ({
  handleClose,
  errorMessage = "SSO account not found.",
}) => {
  return (
    <Grid
      container
      style={{
        display: "flex",
        backgroundColor: "#F8EEEE",
        marginTop: 20,
        justifyContent: "center",
        padding: 20,
        height: "93px",
        border: "2px solid #FF543E",
        flexDirection: "row",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <ErrorIcon /> &nbsp;&nbsp;&nbsp;
        <CustomTypography
          text="Error"
          fontsize="16px"
          fontweight="600"
          fontcolor="#000000"
          justifycontent="flex-left"
          lineheight="24px"
        />
        &nbsp;&nbsp;&nbsp;
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <CloseIcon style={{ cursor: "pointer" }} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={10}
        md={12}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <CustomTypography
          text={errorMessage}
          fontsize="16px"
          fontweight="400"
          fontcolor="#000000"
          justifycontent="flex-left"
          lineheight="24px"
          aligntext="left"
        />
      </Grid>
    </Grid>
  );
};
export default ErrorModal;
