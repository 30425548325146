import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import ReactLoading from "react-loading";
export default function LoadingOverlayComponent({ open, marginLeft = "0px" }) {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <div style={{ marginLeft: marginLeft }}>
          <ReactLoading type={"spin"} color="#003F2D" />
        </div>
      </Backdrop>
    </div>
  );
}
