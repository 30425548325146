/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import { Dialog, makeStyles, Grid, Button } from "@material-ui/core";
import CloseIcon from "../icons/close_icon";
import CustomTypography from "../utils/text";
import WarningMessage from "./warning_snackbar";
import Typography from "@mui/material/Typography";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
  apiEndPoints,
  cssStatics,
  getBreakOptions,
  reportParamters
} from "../apilayer/staticData";
import CircleCompleted from "../icons/Circle_completed";
import CircularUnderLoad from "../icons/circular_UnderLoad";

const useStyles = makeStyles({
  buttonClass: {
    "&:hover": {
      color: "white",
      background: "#003F2D",
    },
    background: "#003F2D",
    color: "white",
    height: 32,
    width: 180,
  },
  disablebuttonClass: {
    "&:hover": {
      color: "white",
      background: "#d4d7d7",
    },
    background: "#d4d7d7",
    height: 32,
    width: 180,
  },
  paper: {
    borderRadius: 10,
    margin: 0,
    overflow: "hidden",
    maxWidth: 624,
  },
});

/**
 * @typedef {Object} DownloadReportModalProps
 * @property {boolean} [open]
 * @property {Function} handleClose
 * @property {reportParamters} [reportParams]
 * @property {Function} onReportTypeSelectionChange
 */

/**
 * @param {DownloadReportModalProps} props
 */

const DownloadReportModal = ({
  open = false,
  handleClose,
  reportParams,
  onReportTypeSelectionChange,
}) => {
  const classes = useStyles();
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    makeGenerateReportCall();
  }, []);

  const getBreakName = (breakId) => {
    var breakDuration = getBreakOptions().filter((user) => {
      return user.id === reportParams.break;
    });
    return breakDuration[0].name;
  };

  const generateReportName = () => {
    return reportParams.reportName + ".xlsx";
  };

  const formatPeriodParams = (dtYYYYMMDD) => {
    const [year, month, day] = dtYYYYMMDD.split("-");
    return day + "-" + month + "-" + year;
  };
  const makeGenerateReportCall = async () => {
    setShowLoading(true);
    var reqdata = {};
    if (reportParams.reportType === 1) {
      reqdata = {
        start_date: reportParams.dateFrom,
        end_date: reportParams.dateTo,
        market: reportParams.marketGroup,
        break: reportParams.break,
        min_size: reportParams.minPropSize,
        max_size: reportParams.maxPropSize,
      };
    } else {
      reqdata = {
        start_date: reportParams.dateFrom,
        end_date: reportParams.dateTo,
        markets: reportParams.marketGroup,
        submarkets: reportParams.subMarket,
        districts: reportParams.district,
        neighbourhoods: reportParams.neightbourhood,
        break: reportParams.break,
        min_size: reportParams.minPropSize,
        max_size: reportParams.maxPropSize,
      };
    }
    try {
      setWarningSnackbar(null);
      var response = await axiosPrivate.post(
        reportParams.reportType === 1
          ? apiEndPoints.QueryDefinedReport
          : apiEndPoints.QueryFlexibleReport,
        reqdata,
        {
          responseType: "blob",
        }
      );
      const href = URL.createObjectURL(response.data);
      const link = document.getElementById("btnDownloadReport");
      link.href = href;
      link.setAttribute("download", generateReportName()); //or any other extension
      setShowLoading(false);
      //URL.revokeObjectURL(href);      //remove ObjectURL
    } catch (error) {
      console.log("Error while making call....", error);
      setWarningSnackbar({
        name: "Error: ",
        message:
          "Sorry, an error occurred while fetching data. Please try again after some time",
      });
      //onReportTypeSelectionChange(-1);
    }
  };

  return (
    <Dialog
      open={open}
      //onClose={handleClose}
      classes={{ paper: classes.paper }}
      PaperProps={{
        sx: {
          width: 624,
          maxHeight: 500,
        },
      }}
    >
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          width: 624,
          height: 400,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 30,
            paddingBottom: 14,
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={10} md={10} style={{ paddingLeft: 215 }}>
              <CustomTypography
                text={"Generating report"}
                fontfamily="Calibre-SB"
                fontsize={cssStatics.fontSize + 10}
                fontweight="500"
                lineheight="32px"
                fontcolor="#1F1E1E"
              />
            </Grid>
            <Grid
              item
              xs={2}
              md={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 30,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container style={{ display: "flex", flexDirection: "row" }}>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 10,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 10,
                textAlign: "center",
              }}
            >
              {!showLoading && <CircleCompleted width={100} height={100} />}
              {showLoading && <CircularUnderLoad svgSize={100} />}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                marginTop: 5,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 10,
              }}
            >
              <Typography
                variant="body2"
                className="centeraligntext centeraligntextbold"
              >
                {generateReportName()}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" className="centeraligntext">
                {"Market group: " +
                  (reportParams.reportType === 1 ? "Predefined" : "Flexible")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" className="centeraligntext">
                {"Period: " +
                  formatPeriodParams(reportParams.dateFrom) +
                  " - " +
                  formatPeriodParams(reportParams.dateTo)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" className="centeraligntext">
                {"Property Size: " +
                  reportParams.minPropSize +
                  " - " +
                  reportParams.maxPropSize +
                  " sq.ft."}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2" className="centeraligntext">
                {"Break: " + getBreakName(reportParams.break)}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div></div>
        <div>
          <Grid container style={{ display: "flex", flexDirection: "column" }}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ paddingLeft: 10, paddingRight: 30, paddingBottom: 5 }}
            >
              <Typography
                variant="body2"
                className="centeraligntext"
                style={{
                  fontSize: cssStatics.fontSize + 2,
                  textAlign: cssStatics.textAlign,
                }}
              ></Typography>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            bottom: 30,
            width: "100%",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={7}
              md={7}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginLeft: 35,
              }}
            >
              <Button
                href="#"
                className={
                  showLoading ? classes.disablebuttonClass : classes.buttonClass
                }
                id="btnDownloadReport"
                disabled={showLoading}
              >
                <CustomTypography
                  text={"Download"}
                  fontsize={cssStatics.fontSize}
                  fontfamily="Calibre-SB"
                  fontweight="500"
                  lineheight="16px"
                  fontcolor="white"
                />
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      {warningSnackbar && (
        <WarningMessage
          open={true}
          //setOpen={() => setWarningSnackbar(null)}
          onCloseHandle={() => {
            //onReportTypeSelectionChange(-1);
            setWarningSnackbar(null);
          }}
          //severity={warningSnackbar.severity}
          duration={1000000}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
    </Dialog>
  );
};
export default DownloadReportModal;
