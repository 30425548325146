import { LogLevel } from "@azure/msal-browser";
let auth = "";
let scopes = [];

auth = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  authority: process.env.REACT_APP_AUTHORITY_RUL,
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
};
//Application scope
scopes = [process.env.REACT_APP_SCOPES_URL];

export const msalConfig = {
  auth: auth,
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: scopes,
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
