import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/calibre-web-bold.ttf";
import "./fonts/calibre-web-regular.ttf";
import { VaultProvider } from "./hooks/VaultContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <VaultProvider>
      <App />
    </VaultProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
