/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";
import CustomTypography from "../utils/text";
import InputAdornment from "@mui/material/InputAdornment";
import CalenderIcon from "../icons/calenderIcon_icon";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    innerHeight: "30",
    fontFamily: "Calibre-R",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    alignSelf: "stretch",
    backgroundColor: "white",
    "&:-webkit-autofill": {
      backgroundColor: "#FFFFFF !important;",
    },
    "& .MuiInputAdornment-root": { paddingRight: 2, marginRight: 1 },
    "& .MuiInputBase-input": { textAlign: "center" },
    "& .MuiInputBase-root": { paddingLeft: 10, paddingRight: 10 },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000de",
      "& legend": {
        fontSize: "10px",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1a1a1a",
      },
      "&:hover fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-error fieldset": {
        borderColor: "#FF543E",
      },
    },
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    "& .MuiSelect-root": {
      color: "red",
    },
    width: 100,
  },
  root: {
    "&:hover": {
      borderColor: "#c4c4c4",
    },
    "&$focused ": {
      borderColor: "#c4c4c4",
    },
    "&.Mui-error": {
      borderColor: "#AB1212",
    },
  },
}));

const SimpleSelect = ({
  title,
  data,
  handleCallback,
  value = null,
  isMapview = true,
  error = false,
  firstValueText,
}) => {
  const [values, setValues] = React.useState(1);
  const classes = useStyles();
  const [dropdownData, setDropdownData] = useState([]);

  function handleChange(event) {
    setValues(event.target.value);
    handleCallback(event.target.value);
  }
  useEffect(() => {
    if (value) {
      setValues(value);
    } else {
      setValues(0);
    }
  }, [value]);
  useEffect(() => {
    if (data?.length > 0) {
      var array = [];
      array.push({ id: 0, name: firstValueText });
      data?.map((obj) => {
        array.push(obj);
      });
      setDropdownData(array);
    }
  }, [data]);

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <TextField
        select
        error={error}
        id="outlined-select"
        label={title}
        inputPlaceholder={"inputPlaceholder"}
        className={classes.textField}
        value={values}
        onChange={handleChange}
        displayEmpty={true}
        style={{ marginTop: "0" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{<CalenderIcon />}</InputAdornment>
          ),
          endAdornment: <InputAdornment position="end">{""}</InputAdornment>,
          disableunderline: !isMapview,
          style: {
            fontWeight: isMapview ? "400" : "bold",
          },
        }}
        InputLabelProps={{
          shrink: true,
          style: {
            color: error ? "#FF543E" : "#1A1A1A",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "20px",
            fontFamily: "Calibre-R",
          },
        }}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        margin="normal"
        variant={isMapview ? "outlined" : "standard"}
      >
        {dropdownData.map((value, index) => {
          return (
            <MenuItem
              disabled={index === 0 ? true : false}
              key={value.name}
              value={value.id}
              style={{
                paddingLeft: isMapview ? "15px" : "9px",
                display: index === 0 ? "none" : "auto",
              }}
            >
              <CustomTypography
                text={value.name}
                fontsize={index === 0 ? "13px" : 14}
                fontweight="100"
                lineheight="19px"
                fontcolor={index === 0 ? "#b3b3b3" : "#1A1A1A"}
                zIndex={0}
                display="contents"
              />
            </MenuItem>
          );
        })}
      </TextField>
    </form>
  );
};

export default SimpleSelect;
