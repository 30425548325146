import React from "react";
import LoginView from "./pages/login_screen";
import NavigationLayout from "./navigation/index";
import ProjectView from "./pages/dashboard";
import PreviousReports from "./pages/previous_reports";
import PDFViewer from "./pages/help";

const routes = [
  {
    path: `/`,
    element: <LoginView />,
  },
  {
    path: `/login`,
    element: <LoginView />,
  },
  {
    path: `/`,
    element: <NavigationLayout />,
    children: [{ path: "/dashboard", element: <ProjectView /> }],
  },
  {
    path: `/`,
    element: <NavigationLayout />,
    children: [{ path: "/previousreports", element: <PreviousReports /> }],
  },
  {
    path: `/`,
    element: <NavigationLayout />,
    children: [{ path: "/help", element: <PDFViewer /> }],
  },
];
export default routes;
