import { Typography } from "@material-ui/core";
import React from "react";
const CustomTypography = ({
  fontsize = 14,
  fontfamily = "Calibre-R",
  fontweight = 400,
  fontcolor = "#1F1E1E",
  text = "Text",
  textAlign = "left",
  lineheight = "12px",
  marginTop = "0px",
  justifycontent = "flex-start",
  display = "flex",
  marginRight = "0px",
  textDecoration = "none",
  textundelineOffset = "3px",
  marginLeft = 0,
  marginBottm = 0,
  ...props
}) => {
  return (
    <Typography
      style={{
        fontFamily: fontfamily,
        fontSize: fontsize,
        fontWeight: fontweight,
        color: fontcolor,
        lineHeight: lineheight,
        display: display,
        justifyContent: justifycontent,
        marginTop: marginTop,
        marginRight: marginRight,
        textDecoration: textDecoration,
        textUnderlineOffset: textundelineOffset,
        marginLeft: marginLeft,
        marginBottom: marginBottm,
        overflow: "hidden",
        textAlign,
        ...props,
      }}
      {...props}
    >
      {text}
    </Typography>
  );
};
export default CustomTypography;
