import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, makeStyles } from "@material-ui/core";
import DownArrowIcon from "../icons/down_arrow_icon";
import CalenderIcon from "../icons/calenderIcon_icon";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000de",
      "& legend": {
        fontSize: "13px",
      },
    },
    "& .MuiInputAdornment-root": {
      marginRight: -15,
    },
    backgroundColor: "white",
    "&:-webkit-autofill": {
      backgroundColor: "#FFFFFF !important;",
    },
    "& .MuiFormLabel-root": {
      zIndex: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1a1a1a",
      },
      "&:hover fieldset": {
        borderColor: "#205747",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#205747",
      },
      "& .MuiOutlinedInput-input": {
        fontSize: 14,
        fontFamily: "Calibre-R",
        textAlign: "center",
      },
    },
  },
  focused: {},
  notchedOutline: {
    "&.MuiOutlinedInput-root.Mui-error": {
      borderColor: "#205747 !important",
    },
  },
}));
const DatePickerComponent = ({
  callback = null,
  height = 56,
  width = 298,
  maxDate = "+99y",
  minDate = new Date("01-01-2002"), //moment(),
  label,
  preselectedValue = null,
  handleResetValue = null,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [val, setVale] = useState();
  const outlinedInputClasses = useOutlinedInputStyles();
  return (
    <Grid
      data-qaid="datePicker_main_grid"
      style={{ width: width, height: height }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["year", "month", "day"]}
          label={label}
          width={width}
          disabled={disabled}
          makeStyles={{ color: "#000000" }}
          minDate={minDate}
          maxDate={maxDate}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={(val == null ? preselectedValue : val) || null}
          inputFormat="YYYY-MM-DD"
          onChange={(newValue) => {
            callback(newValue);
            setVale(newValue);
          }}
          InputAdornmentProps={{ position: "start" }}
          components={{
            OpenPickerIcon: CalenderIcon,
          }}
          InputProps={{
            endAdornment: (
              <div style={{ marginRight: 0, cursor: "pointer" }}>
                <DownArrowIcon />
              </div>
            ),
          }}
          renderInput={(params) => (
            <TextField
              disabled={disabled}
              style={{
                backgroundColor: "#FFFFFF",
                width: "100%",
                height: "54px",
                fontFamily: "Calibri-R",
                fontSize: "4px",
                lineHeight: "16px",
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: "#1A1A1A",
                  fontSize: "20px",
                  marginTop: 0,
                  paddingRight: 16,
                  lineHeight: "13px",
                  fontFamily: "Calibre-R",
                },
              }}
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "Choose Date",
              }}
              value={preselectedValue || null}
              className={outlinedInputClasses.root}
              components={{
                OpenPickerIcon: CalenderIcon,
              }}
              onClick={(e) => !disabled && setOpen(true)}
            />
          )}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export default DatePickerComponent;
