/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "../icons/close_icon";
import CircleClick from "../icons/circle_check";
import CustomTypography from "../utils/text";
import { Grid } from "@material-ui/core";
const SnackBar = ({
  open,
  setOpen,
  message,
  duration = 4000,
  name = "",
  source = "",
}) => {
  const [vertical, setVertical] = useState("top");
  const [horizontal, setHorizontal] = useState("center");
  const handleClose = (event) => {
    if (setOpen) setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      style={{ height: 65 }}
    >
      <Alert
        icon={
          <CircleClick
            fontSize="inherit"
            color={"#1C7D26"}
            width={18}
            height={18}
            style={{ alignItems: "center" }}
          />
        }
        variant="outlined"
        elevation={6}
        style={{
          backgroundColor: "#E6F4EC",
          border: "1px solid #50AF78",
          display: "flex",
          alignItems: "center",
        }}
        action={
          <CloseIcon
            color={"#012A2D"}
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() => {
              handleClose();
            }}
          />
        }
      >
        {source === "edit" ? (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <CustomTypography
              text={message}
              fontsize="16px"
              fontweight="400"
              fontcolor="#000000"
              lineheight="24px"
              justifycontent="center"
            />
            {"\u00A0"}
            <CustomTypography
              text={name?.length > 50 ? name?.slice(0, 50) + "..." : name}
              fontsize="16px"
              fontweight="600"
              fontcolor="#000000"
              lineheight="24px"
              justifycontent="center"
              marginRight={5}
            />
          </Grid>
        ) : (
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <CustomTypography
              text={name?.length > 50 ? name.slice(0, 50) + "..." : name}
              fontsize="16px"
              fontweight="600"
              fontcolor="#000000"
              lineheight="24px"
              justifycontent="center"
              marginRight={5}
            />
            <CustomTypography
              text={message}
              fontsize="16px"
              fontweight="400"
              fontcolor="#000000"
              lineheight="24px"
              justifycontent="center"
            />
          </Grid>
        )}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
