import React from "react";

function DashboardIcon({ color = "#1A1A1A", width = 20, height = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={color}
        d="M0 10h8V0H0v10zm0 8h8v-6H0v6zm10 0h8V8h-8v10zm0-18v6h8V0h-8z"
      ></path>
    </svg>
  );
}

export default DashboardIcon;
