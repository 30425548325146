import React, { useState } from "react";
import LoadingOverlayComponent from "../components/lodding_component";

function PDFViewer() {
  const [loading, setLoading] = useState(true);

  return (
    <div>
      {loading && <LoadingOverlayComponent open={loading} />}
      <iframe
        src="User Stats Report_Getting Started Guide.pdf"
        width="100%"
        height="700px"
        style={{ display: loading ? "none" : "block" }}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}

export default PDFViewer;
