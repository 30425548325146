import React from "react";
function CircleCompleted({ width = "100", height = "100" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 100 100"
    >
      <circle cx="50" cy="50" r="50" fill="#E6F4EC"></circle>
      <path
        stroke="#003F2D"
        strokeWidth="3"
        d="M40.476 51.19l7.018 7.143 12.03-17.857m9.523 8.929c0 10.848-8.794 19.642-19.642 19.642-10.849 0-19.643-8.794-19.643-19.642 0-10.849 8.794-19.643 19.643-19.643 10.848 0 19.642 8.794 19.642 19.643z"
      ></path>
    </svg>
  );
}
export default CircleCompleted;
