/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import { Card, CardContent, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTypography from "../utils/text";
import WarningMessage from "../components/warning_snackbar";
import SnackBar from "../components/SnackBar";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableSortLabel,
} from "@material-ui/core";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontFamily: "Calibre-R",
    fontWeight: "bold",
    backgroundColor: "inherit !important",
    fontSize: "14px",
    lineHeight: "21px",
  },
}));

const PreviousReports = () => {
  const [warningSnackbar, setWarningSnackbar] = useState(null);
  const [snackbar, setSnackbar] = useState(null);

  function generateData() {
    const data = [];
    for (let i = 0; i < 100; i++) {
      data.push({
        GeneratedOn: `0${i + 1}/2023`,
        ReportName: `Stats-Sample-rep-${i + 1}.xlsx`,
        MarketInput: "Defined market groups",
        Period: `01/0${i + 1}/2023 - 01/0${i + 2}/2023`,
        Break: i % 2 === 0 ? "Monthly" : "Anually",
        Action: `Stats-Sample-rep-${i + 1}.xlsx`,
      });
    }
    return data;
  }

  const initialRows = generateData();

  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState(initialRows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("col1");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    if (event.target.value === "") {
      setRows(initialRows);
    } else {
      setRows(
        initialRows.filter((row) =>
          Object.values(row).some((s) =>
            s.toLowerCase().includes(event.target.value.toLowerCase())
          )
        )
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRows = rows.sort(
    (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1) * (order === "asc" ? 1 : -1)
  );

  return (
    <div style={{ backgroundColor: "#EEF1F1", width: "100%", height: "100%" }}>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          paddingLeft: 60,
          paddingRight: 60,
          paddingTop: 10,
        }}
      >
        <Grid item md={7}>
          <CustomTypography
            text="Previous reports"
            fontsize="36px"
            fontweight="400"
            lineheight="40px"
            fontcolor="#1A1A1A"
            marginTop="40px"
          />
        </Grid>
        <Grid
          item
          md={5}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginTop: "45px",
            paddingRight: 15,
          }}
        ></Grid>
      </Grid>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            marginTop: 40,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 10,
            textAlign: "center",
            height: "50vh",
            position: "relative",
          }}
        >
          <div>
            <TextField
              label="Search"
              value={searchText}
              onChange={handleSearchChange}
            />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {[
                      "Generated On",
                      "Report Name",
                      "Market Input",
                      "Period",
                      "Break",
                      "Action",
                    ].map((column, index) => (
                      <TableCell key={index}>
                        <TableSortLabel
                          active={orderBy === `col${index + 1}`}
                          direction={order}
                          onClick={handleSort(`col${index + 1}`)}
                        >
                          {column}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.GeneratedOn}</TableCell>
                        <TableCell>{row.ReportName}</TableCell>
                        <TableCell>{row.MarketInput}</TableCell>
                        <TableCell>{row.Period}</TableCell>
                        <TableCell>{row.Break}</TableCell>
                        <TableCell>{row.Action}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                count={rows.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      {warningSnackbar && (
        <WarningMessage
          open={true}
          setOpen={() => setWarningSnackbar(null)}
          severity={warningSnackbar.severity}
          name={warningSnackbar.name}
          message={warningSnackbar.message}
        />
      )}
      {snackbar && (
        <SnackBar
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          name={snackbar.name}
          message={snackbar.message}
        />
      )}
    </div>
  );
};
export default PreviousReports;
