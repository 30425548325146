import React from "react";

function CBREIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96.55"
      height="24"
      fill="none"
      viewBox="0 0 96.55 24"
    >
      <path
        fill="#003F2D"
        d="M75.545.004h21.004l-.01 5.226H81.38l.015 3.86H95.06v5.255H81.396v4.178h15.159L96.55 24H75.545V.004zm-12.219.03H50.624v23.959h5.833v-9.694l6.423-.006c1.84.027 3.271 1.534 3.271 3.392v6.287l5.71-.002-.011-7.732c0-3.583-3.461-4.642-3.461-4.642s3.538-1.057 3.538-5.292c0-5.182-4.452-6.27-8.6-6.27zm-40.96 18.17c-.084.002-7.973.154-10.697-.111-4.386-.43-5.94-3.68-5.94-6.332 0-3.307 2.254-5.74 5.746-6.198 1.662-.218 10.746-.114 10.844-.113l.19.002.019-5.448h-.192L12.053.023c-1.145.07-3.043.185-4.987.98a12.199 12.199 0 00-4.704 3.804A11.9 11.9 0 000 11.947c0 .816.065 1.624.196 2.4.74 3.633 3.121 6.64 6.535 8.256 1.153.508 2.931 1.115 6.517 1.316l1.932.041c0 .002 7.169.008 7.169.008h.189l.02-5.767-.192.003zm21.531-6.595c3.051-1.014 3.605-3.575 3.597-5.663C47.49 2.758 45.238.002 38.58.002L26.15 0v23.95h12.398c6.699 0 9.164-3.44 9.164-6.884 0-4.172-3.814-5.457-3.814-5.457zm-3.882 6.76h-7.967v-4.01h8.161l.02.002c1.015.117 1.783.96 1.783 1.964 0 1.093-.91 2.028-1.997 2.043zM32.048 5.21h8.37l.03.005c.944.168 1.631.971 1.631 1.913 0 .965-.736 1.873-1.71 2.005l-8.315.014-.006-3.937zM66.51 7.467c-.104.788-.892 1.643-1.875 1.643l-8.198.002V5.277h8.201c.98 0 1.747.72 1.872 1.667v.523z"
      ></path>
    </svg>
  );
}

export default CBREIcon;
